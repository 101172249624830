// JavaScript Document

// Scripts written by Jordan Bearce @ Weblinx, Inc.

import { library, dom } from "@fortawesome/fontawesome-svg-core";

import { faAngleRight               as fasFaAngleRight          } from "@fortawesome/pro-solid-svg-icons/faAngleRight";
import { faBars                     as fasFaBars                } from "@fortawesome/pro-solid-svg-icons/faBars";
import { faCaretDown                as fasFaCaretDown           } from "@fortawesome/pro-solid-svg-icons/faCaretDown";
import { faCaretLeft                as fasFaCaretLeft           } from "@fortawesome/pro-solid-svg-icons/faCaretLeft";
import { faCaretRight               as fasFaCaretRight          } from "@fortawesome/pro-solid-svg-icons/faCaretRight";
import { faCircleQuestion           as fasFaCircleQuestion      } from "@fortawesome/pro-solid-svg-icons/faCircleQuestion";
import { faCircleUser               as fasFaCircleUser          } from "@fortawesome/pro-solid-svg-icons/faCircleUser";
import { faCircleXmark              as fasFaCircleXmark         } from "@fortawesome/pro-solid-svg-icons/faCircleXmark";
import { faClock                    as fasFaClock               } from "@fortawesome/pro-solid-svg-icons/faClock";
import { faComment                  as fasFaComment             } from "@fortawesome/pro-solid-svg-icons/faComment";
import { faFolder                   as fasFaFolder              } from "@fortawesome/pro-solid-svg-icons/faFolder";
import { faMagnifyingGlass          as fasFaMagnifyingGlass     } from "@fortawesome/pro-solid-svg-icons/faMagnifyingGlass";
import { faTag                      as fasFaTag                 } from "@fortawesome/pro-solid-svg-icons/faTag";
import { faLanguage                 as fasFaLanguage            } from "@fortawesome/pro-solid-svg-icons/faLanguage";
import { faPlug                     as fasFaPlug                } from "@fortawesome/pro-solid-svg-icons/faPlug";
import { faClipboardListCheck       as fasFaClipboardListCheck  } from "@fortawesome/pro-solid-svg-icons/faClipboardListCheck";
import { faAddressBook              as fasFaAddressBook         } from "@fortawesome/pro-solid-svg-icons/faAddressBook";
import { faMapLocationDot           as fasFaMapLocationDot      } from "@fortawesome/pro-solid-svg-icons/faMapLocationDot";
import { faMessages                 as fasFaMessages            } from "@fortawesome/pro-solid-svg-icons/faMessages";
import { faBriefcaseBlank           as fasFaBriefcaseBlank      } from "@fortawesome/pro-solid-svg-icons/faBriefcaseBlank";
import { faFilePdf                  as fasFaFilePdf             } from "@fortawesome/pro-solid-svg-icons/faFilePdf";

import { faFilePdf                  as farFaFilePdf             } from "@fortawesome/pro-regular-svg-icons/faFilePdf";
import { faAngleRight               as farFaAngleRight          } from "@fortawesome/pro-regular-svg-icons/faAngleRight";
import { faFile                     as farFaFile                } from "@fortawesome/pro-regular-svg-icons/faFile";
import { faFileAudio                as farFaFileAudio           } from "@fortawesome/pro-regular-svg-icons/faFileAudio";
import { faFileCode                 as farFaFileCode            } from "@fortawesome/pro-regular-svg-icons/faFileCode";
import { faFileExcel                as farFaFileExcel           } from "@fortawesome/pro-regular-svg-icons/faFileExcel";
import { faFileImage                as farFaFileImage           } from "@fortawesome/pro-regular-svg-icons/faFileImage";
import { faFileLines                as farFaFileLines           } from "@fortawesome/pro-regular-svg-icons/faFileLines";
import { faFilePowerpoint           as farFaFilePowerpoint      } from "@fortawesome/pro-regular-svg-icons/faFilePowerpoint";
import { faFileVideo                as farFaFileVideo           } from "@fortawesome/pro-regular-svg-icons/faFileVideo";
import { faFileWord                 as farFaFileWord            } from "@fortawesome/pro-regular-svg-icons/faFileWord";
import { faFileZipper               as farFaFileZipper          } from "@fortawesome/pro-regular-svg-icons/faFileZipper";
import { faFolder                   as farFaFolder              } from "@fortawesome/pro-regular-svg-icons/faFolder";

/**
 * Add solid icons
 */
library.add(fasFaAngleRight, fasFaBars, fasFaCaretDown, fasFaCaretLeft, fasFaCaretRight, fasFaCircleQuestion, fasFaCircleUser, fasFaClock, fasFaCircleXmark, fasFaComment, fasFaFolder, fasFaMagnifyingGlass, fasFaTag, fasFaLanguage, fasFaPlug, fasFaClipboardListCheck, fasFaAddressBook, fasFaMapLocationDot, fasFaLanguage, fasFaMessages, fasFaBriefcaseBlank, fasFaFilePdf);

/**
 * Add solid icons
 */
library.add(farFaFilePdf, farFaAngleRight, farFaFile, farFaFileAudio, farFaFileCode, farFaFileExcel, farFaFileImage, farFaFileLines, farFaFilePowerpoint, farFaFileVideo, farFaFileWord, farFaFileZipper, farFaFolder);

/**
 * Watch the DOM to insert icons
 */
dom.watch();
